<template>
  <div class="container topCon">
    <div class="ztTop"><span class="ztTitle">主体信息</span></div>
    <div class="wrapContent">
      <div class="data-hd">
        <p>营业执照<span class="desc_info">请上传"营业执照"，需年检章齐全，当年注册除外</span></p>
      </div>
      <div class="data-db">
        <el-form ref="form" :model="form" label-width="150px" :rules="formRules" size="medium">
          <el-form-item label="营业执照照片" prop="businessLicenseInfo.licenseCopy">
              <el-upload
                  :auto-upload="true"
                  :action="uploadUrl"
                  :headers="upParams"
                  class="avatar-uploader myAvatarUploader"
                  :show-file-list="false"
                  :on-success="handleLicenseCopySuccess"
                  >
                <el-image  v-if="licenseCopyImg" :src=" ossUrl + licenseCopyImg" class="avatar" fit="contain" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip desc_info">请上传彩色照片 or 彩色扫描件 or 加盖公章鲜章的复印件，可添加“微信支付”相关水印（如微信支付认证）</div>
              </el-upload>
          </el-form-item>
          <el-form-item label="注册号" prop="businessLicenseInfo.licenseNumber">
            <el-input v-model="form.businessLicenseInfo.licenseNumber"></el-input>
          </el-form-item>
          <el-form-item label="营业执照注册地址" prop="businessLicenseInfo.licenseAddress">
            <el-input v-model="form.businessLicenseInfo.licenseAddress"></el-input>
          </el-form-item>
          <el-form-item label="执照有效期类型" prop="licenseAddress">
            <el-radio v-model="term" label="定期">定期</el-radio>
            <el-radio v-model="term" label="长期">长期</el-radio>
          </el-form-item>
          <el-form-item label="执照生效日期" prop="businessLicenseInfo.periodBegin">
            <el-date-picker
                v-model="form.businessLicenseInfo.periodBegin"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="执照失效日期" prop="businessLicenseInfo.periodEnd" v-if="term == '定期'">
            <el-date-picker
                v-model="form.businessLicenseInfo.periodEnd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="商户名称" prop="businessLicenseInfo.merchantName">
            <el-input v-model="form.businessLicenseInfo.merchantName"></el-input>
          </el-form-item>
          <el-form-item label="法定代表人姓名" prop="businessLicenseInfo.legalPerson">
            <el-input v-model="form.businessLicenseInfo.legalPerson"></el-input>
          </el-form-item>
          <div class="data-hd">
            <p>金融机构许可证</p>
          </div>
          <el-form-item label="是否金融机构" prop="financeInstitution">
            <el-select v-model="financeInstitution" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <p class="descWord">金融机构是指从事金融类业务的机构，如信贷、融资、理财、担保、信托、货币兑换等。</p>
          </el-form-item>
          <el-form-item label="金融机构类型" prop="financeType"  v-if="financeInstitution">
            <el-select v-model="financeType" placeholder="请选择" >
              <el-option
                  v-for="item in financeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="金融机构许可证" v-if="financeInstitution" prop="financeInstitutionInfo.financeLicensePicsList" > <!--prop="financeInstitution.financeLicensePics"-->
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleimgsUrl"
                :file-list.sync="financeLicensePicsFileList"
                accept="image/jpeg,image/png"
                :limit="5"
                ref="upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
<!--            <el-upload-->
<!--                :auto-upload="true"-->
<!--                :action="uploadUrl"-->
<!--                :headers="upParams"-->
<!--                class="avatar-uploader"-->
<!--                :show-file-list="false"-->
<!--                :on-success="handleFinanceInstitutionSuccess"-->
<!--                >-->
<!--              <el-image  v-if="financeLicensePics" :src=" ossUrl +financeLicensePics" class="avatar" fit="contain"></el-image>-->
<!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--            </el-upload>-->
          </el-form-item>
          <div class="data-hd">
            <p>法定代表人证件<span class="desc_info">请上传法定代表人的证件信息</span></p>
          </div>
          <el-form-item label="证件类型" prop="identityInfo.idDocType" >
            <el-select v-model="form.identityInfo.idDocType" placeholder="请选择">
              <el-option
                  v-for="item in idDocOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证人像面照片" prop="identityInfo.idCardInfo.idCardCopy">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                class="avatar-uploader myAvatarUploader"
                :show-file-list="false"
                :on-success="handleIdCardCopySuccess"
                >
              <el-image fit="contain" v-if="idCardCopy" :src="ossUrl + idCardCopy" class="avatar"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="身份证国徽面照片" prop="identityInfo.idCardInfo.idCardNational">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                class="avatar-uploader myAvatarUploader"
                :show-file-list="false"
                :on-success="handleIdCardNationalSuccess"
            >
              <el-image fit="contain" v-if="idCardNational" :src="ossUrl + idCardNational" class="avatar"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="身份证号码" prop="identityInfo.idCardInfo.idCardNumber">
            <el-input v-model="form.identityInfo.idCardInfo.idCardNumber"></el-input>
          </el-form-item>
          <el-form-item label="身份证姓名" prop="identityInfo.idCardInfo.idCardName">
            <el-input v-model="form.identityInfo.idCardInfo.idCardName"></el-input>
          </el-form-item>
          <el-form-item label="身份证居住地址" prop="identityInfo.idCardInfo.idCardAddress">
            <el-input v-model="form.identityInfo.idCardInfo.idCardAddress"></el-input>
          </el-form-item>
          <el-form-item label="证件有效期类型" prop="cardTime">
            <el-radio v-model="cardTime" label="定期">定期</el-radio>
            <el-radio v-model="cardTime" label="长期">长期</el-radio>
          </el-form-item>
          <el-form-item label="证件生效日期" prop="identityInfo.idCardInfo.cardPeriodBegin">
            <el-date-picker
                v-model="form.identityInfo.idCardInfo.cardPeriodBegin"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="证件失效日期" prop="identityInfo.idCardInfo.cardPeriodEnd" v-if="cardTime == '定期'">
            <el-date-picker
                v-model="form.identityInfo.idCardInfo.cardPeriodEnd"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
<!--          <el-form-item label="是否为受益所有人" prop="identityInfo.owner">-->
<!--            <el-radio v-model="form.identityInfo.owner" label="true">是</el-radio>-->
<!--            <el-radio v-model="form.identityInfo.owner" label="false">否,非经营者</el-radio>-->
<!--            <el-tooltip placement="right">-->
<!--              <div style="font-size: 15px" slot="content">根据国家相关法律法规，您需要提供公司受益所有人信息。受益所有人需符合至少以下条件之一：<br/>1. 直接或者间接拥有超过25%公司股权或者表决权的自然人；-->
<!--                <br/>2. 通过人事、财务等其他方式对公司进行控制的自然人；<br/>3. 公司的高级管理人员，包括公司的经理、副经理、财务负责人，上市公司董事会秘书和公司章程规定的其他人员"；-->
<!--              </div>-->
<!--              <span><i style="font-size: 16px" class="el-icon-question" /></span>-->
<!--            </el-tooltip>-->
<!--          </el-form-item>-->
        </el-form>
        <div class="bottomBtn">
          <el-button class="btn-defalut" @click="backToMerchantApply">返回</el-button>
          <el-button class="btn-defalut" @click="saveInfo" v-if="isSee == 'false'">保存草稿箱</el-button>
          <el-button class="success btn-defalut" @click="toNext" v-if="isSee == 'false'">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addSubjectInfo, getSubjectInfo} from "../../../api/wxMerchant";
import {baseImgUrl} from "../../../utils/constant";

export default {
  name: "merchantSubjectInfo",
  components: {},
  data() {
    var validateLicenseCopy = (rule, value, callback) => {
      if (!this.licenseCopyImg) {
        callback(new Error('请上传营业执照'))
      } else {
        callback()
      }
    }
    var validateIdCardNational = (rule, value, callback) => {
      if (!this.idCardNational) {
        callback(new Error('请上传身份证国徽面照片'))
      } else {
        callback()
      }
    }
    var validateIdCardCopy = (rule, value, callback) => {
      if (!this.idCardCopy) {
        callback(new Error('请上传身份证人像面照片'))
      } else {
        callback()
      }
    }
    var validateFinanceLicensePics = (rule, value, callback) => {
      if (this.financeLicensePicsFileList.length <= 0) {
        callback(new Error('请上传金融机构许可证'))
      } else {
        callback()
      }
    }
    let token = localStorage.getItem("token")
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      options:[
        {
          value: true,
          label: '是'
        }, {
          value: false,
          label: '否'
        }
      ],
      financeOption:[
        {
          value: 'BANK_AGENT',
          label: '银行业'
        }, {
          value: 'PAYMENT_AGENT',
          label: '支付机构'
        },
        {
          value: 'INSURANCE',
          label: '保险业'
        }, {
          value: 'TRADE_AND_SETTLE',
          label: '交易及结算类金融机构'
        }, {
          value: 'OTHER',
          label: '其他金融机构'
        }
      ],
      idDocOption:[
        {
          value: 'IDENTIFICATION_TYPE_IDCARD',
          label: '中国大陆居民-身份证'
        }
        // , {
        //   value: 'IDENTIFICATION_TYPE_OVERSEA_PASSPORT',
        //   label: '其他国家或地区居民-护照'
        // },
        // {
        //   value: 'IDENTIFICATION_TYPE_HONGKONG_PASSPORT',
        //   label: '中国香港居民-来往内地通行证'
        // }, {
        //   value: 'IDENTIFICATION_TYPE_MACAO_PASSPORT',
        //   label: '中国澳门居民-来往内地通行证'
        // }, {
        //   value: 'IDENTIFICATION_TYPE_TAIWAN_PASSPORT',
        //   label: '中国台湾居民-来往大陆通行证'
        // }, {
        //   value: 'IDENTIFICATION_TYPE_FOREIGN_RESIDENT',
        //   label: '外国人居留证'
        // }, {
        //   value: 'IDENTIFICATION_TYPE_HONGKONG_MACAO_RESIDENT',
        //   label: '港澳居民证'
        // }, {
        //   value: 'IDENTIFICATION_TYPE_TAIWAN_RESIDENT',
        //   label: '台湾居民证'
        // }
      ],
      term:'定期',
      subject_info:{},
      cardTime:'定期',
      form:{
        businessLicenseInfo:{
          
        },//营业执照相关信息
        financeInstitution:false,//是否是金融类
        financeInstitutionInfo:{},//金融机构信息
        identityInfo:{
          idDocType:'IDENTIFICATION_TYPE_IDCARD',
          idCardInfo:{},
          owner:'true'
        },//经营者/法人身份证件信息
      },
      dialogVisible:false,
      dialogImageUrl:'',
      isSee:false,
      financeType:'',
      financeInstitution:false,
      merchantId:'',
      uploadUrl:this.$store.state.uploadingUrl,
      licenseCopyImg:'',
      idCardNational:'',
      idCardCopy:'',
      financeLicensePicsFileList:[],
      ossUrl: this.$store.state.ossUrl,
      upParams: {
        token: token
      },
      formRules: {
        'identityInfo.idCardInfo.idCardCopy':[
          { required: true, message: '请上传身份证人像面照片', trigger: 'change' },
          { validator:validateIdCardCopy, trigger: 'blur' },
        ],
        'identityInfo.idCardInfo.idCardNational':[
          { required: true, message: '请上传身份证国徽面照片', trigger: 'change' },
          { validator:validateIdCardNational, trigger: 'blur' },
        ],
        // 'financeInstitution.financeLicensePics':[
        //   { required: true, message: '请上传金融机构许可证', trigger: 'change' },
        //   { validator:validateFinanceLicensePics,  trigger: 'blur' },
        // ],
        'businessLicenseInfo.licenseCopy':[
          { required: true, message: '请上传营业执照', trigger: 'change' },
          { validator:validateLicenseCopy , trigger: 'change' },
        ],
        'businessLicenseInfo.licenseNumber':[
          { required: true, message: '请填写营业执照注册号', trigger: 'blur' },
        ],
        'businessLicenseInfo.licenseAddress':[
          { required: true, message: '请填写营业执照注册地址', trigger: 'blur' },
        ],
        'businessLicenseInfo.periodBegin':[
          {required: true, message: '请填写执照生效日期', trigger: 'blur' },
        ],
        'businessLicenseInfo.merchantName':[
          { required: true, message: '请填写商户名称', trigger: 'blur' },
        ],
        'businessLicenseInfo.periodEnd':[
          {required: true, message: '请填写执照失效日期', trigger: 'blur' },
        ],
        'businessLicenseInfo.legalPerson':[
          { required: true, message: '请填写法定代表人姓名', trigger: 'blur' },
        ],
        financeInstitution:[
          { required: true, message: '请填写法定代表人姓名', trigger: 'blur' },
        ],
        'identityInfo.idDocType':[
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        'identityInfo.idCardInfo.idCardNumber':[
          { required: true, message: '请填写身份证号码', trigger: 'blur' },
        ],
        'identityInfo.idCardInfo.idCardName':[
          { required: true, message: '请填写身份证姓名', trigger: 'blur' },
        ],
        'identityInfo.idCardInfo.idCardAddress':[
          { required: true, message: '请填写身份证住址', trigger: 'blur' },
        ],
        'identityInfo.idCardInfo.cardPeriodBegin':[
          { required: true, message: '请填写证件生效日期', trigger: 'blur' },
        ],
        'identityInfo.idCardInfo.cardPeriodEnd':[
          { required: true, message: '请填写证件失效日期', trigger: 'blur' },
        ],
        'identityInfo.owner':[
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        'financeInstitutionInfo.financeLicensePicsList': [
          { type: 'array', required: true, message: '请上传金融机构许可证', trigger: ['blur','change'] },
          { validator:validateFinanceLicensePics, trigger: ['blur','change'] },
        ]
      }
    };

  },
  computed: {},
  filters:{
    formatType(type){
      if ('SUBJECT_TYPE_INDIVIDUAL'==type){
        return '个体户'
      }else if ('SUBJECT_TYPE_ENTERPRISE' == type){
        return '企业'
      }else if ('SUBJECT_TYPE_GOVERNMENT'==type){
        return '政府机关'
      }else if ('SUBJECT_TYPE_INSTITUTIONS'==type){
        return '事业单位'
      }else if ('SUBJECT_TYPE_OTHERS'==type){
        return '社会组织'
      }
    }
  },
  watch: {},
  mounted() {

  },
  created() {
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
    this.merchantId = this.$route.query.merchantId
    this.isSee = this.$route.query.isSee
    this.getSubjectInfo()
  },
  methods: {
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.financeLicensePicsFileList = fileList
      let reg = new RegExp(baseImgUrl, "g")
      this.form.financeInstitutionInfo.financeLicensePicsList = this.financeLicensePicsFileList.map(item => item.url.replace(reg, ''))
      // console.log('444', this.form.financeInstitutionInfo.financeLicensePicsList)
    },
    handleimgsUrl(response, file, fileList) {
      if (response.code === 200) {
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        // console.log('0000', this.financeLicensePicsFileList)
        this.financeLicensePicsFileList = this.financeLicensePicsFileList.push({
          name: file.name,
          url: baseImgUrl + response.data,
        })
        // let reg = new RegExp(baseImgUrl, "g")
        // this.form.financeInstitutionInfo.financeLicensePicsList = this.financeLicensePicsFileList.map(item => item.url.replace(reg, ''))
      }else {
        this.$message({
          message: response.message,
          type: 'error'
        });
        fileList.pop()
        this.financeLicensePicsFileList = fileList
        console.log('333', this.financeLicensePicsFileList)
      }
    },
    getSubjectInfo(){
      getSubjectInfo({merchantId:this.merchantId}).then(response=>{
        if (response.code == 200 && response.data.businessLicenseInfo){
          let than = this
          than.form = response.data
          // this.form.businessLicenseInfo = response.data.businessLicenseInfo
          // this.form.identityInfo = response.data.identityInfo
          than.licenseCopyImg = than.form.businessLicenseInfo.licenseCopy
          than.idCardCopy = than.form.identityInfo.idCardInfo.idCardCopy
          than.idCardNational = than.form.identityInfo.idCardInfo.idCardNational
          if (response.data.identityInfo.idCardInfo.cardPeriodEnd == '长期'){
            than.form.identityInfo.idCardInfo.cardPeriodEnd = ''
            than.cardTime = '长期'
          }else {
            than.cardTime = '定期'
          }
          if (response.data.businessLicenseInfo.periodEnd == '长期') {
            than.term = '长期'
            than.form.businessLicenseInfo.periodEnd = ''
          }else {
            than.term = '定期'
          }
          if (response.data.identityInfo.owner){
            than.form.identityInfo.owner = 'true'
          }else{
            than.form.identityInfo.owner = 'false'
          }
          than.financeInstitution = response.data.financeInstitution
          if (response.data.financeInstitutionInfo){
            
            than.financeType = response.data.financeInstitutionInfo.financeType
            than.form.financeInstitutionInfo = response.data.financeInstitutionInfo
            if(response.data.financeInstitutionInfo.financeLicensePicsList) {
              than.financeLicensePicsFileList = response.data.financeInstitutionInfo.financeLicensePicsList.map(item => {
                return {
                  name: '',
                  url: baseImgUrl + item,
                }
              })
            }
          }
        }

      })
    },
    handleLicenseCopySuccess(res, file){
      this.form.businessLicenseInfo.licenseCopy =   res.data;
      this.licenseCopyImg =  this.form.businessLicenseInfo.licenseCopy
    },
    handleFinanceInstitutionSuccess(res,file){
      this.form.financeInstitutionInfo.financeLicensePics=res.data
      this.financeLicensePics =  this.form.financeInstitutionInfo.financeLicensePics
    },
    handleIdCardCopySuccess(res,file){
      this.form.identityInfo.idCardInfo.idCardCopy = res.data
      this.idCardCopy =  this.form.identityInfo.idCardInfo.idCardCopy
    },
    handleIdCardNationalSuccess(res,file){
      this.form.identityInfo.idCardInfo.idCardNational = res.data
      this.idCardNational =  this.form.identityInfo.idCardInfo.idCardNational
    },
    toNext(){
      //跳转下个页面且将数据进行保存
      this.$refs.form.validate(async (valid) => {
        if (valid) {
         this.form.merchantId = this.merchantId
         //
         
        if (this.cardTime == '长期'){
            this.form.identityInfo.idCardInfo.cardPeriodEnd = '长期'
        }
        if (this.term == '长期'){
          this.form.businessLicenseInfo.periodEnd = '长期'
        }

        // this.form.financeInstitutionInfo = {
        //   financeType:this.financeType,
        //   financeLicensePicsList:this.financeLicensePicsFileList
        // }
        this.form.financeInstitution = this.financeInstitution
        this.form.isSubmit = true
        addSubjectInfo(this.form).then(res => {
            if (res.code === 200) {
              this.$router.push({path:'/managementInfo',query:{'merchantId':this.merchantId,isWrite:true,isSee:this.isSee}})
              this.$message({
                message: '操作成功',
                type: 'success'
              });

            }
          })
        }
      })
    },
    saveInfo(){
      this.form.merchantId = this.merchantId
      if (this.cardTime == '长期'){
        this.form.identityInfo.idCardInfo.cardPeriodEnd = '长期'
      }
      if (this.term == '长期'){
        this.form.businessLicenseInfo.periodEnd = '长期'
      }
      this.form.financeInstitution = this.financeInstitution
      // this.form.financeInstitutionInfo = {
      //   financeType:this.financeType,
      //   financeLicensePicsList:this.financeLicensePicsFileList
      // }
      this.form.financeInstitutionInfo.financeType = this.financeType
      addSubjectInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      })
    },
    backToMerchantApply(){
      this.$message.closeAll()
      let than = this
      setTimeout(function () {
        than.$router.push({path:'/merchantApplyInfo',query:{merchantId:than.merchantId,isSee:than.isSee}})
      },0)
    }
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.topCon {
  position: relative;

}
.ztTop{
  margin-top: 20px;
  margin-bottom: 20px;
}
.wrapContent{
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px -1px rgb(0 0 0 / 8%);
}
.data-hd{
  margin-bottom: 30px;
  height: 14px;
  line-height: 1;
  padding-left: 10px;
  border-left: 3px solid #00c250;
}
.desc_info{
  font-size: 12px;
  color: #999;
  margin-left: 15px;
}
.descWord{
  color: #999;
  margin-top: 10px;
}
/* .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
} */
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 154px;
  height: 109px;
  position: relative;
  overflow: hidden;
}
.myAvatarUploader /deep/ .avatar-uploader-icon {
  line-height: 109px;
  font-size: 28px;
}
/* .avatar-uploader-icon[data-v-e66b3640] {
  font-size: 28px;
  color: #8c939d;
  width: 154px;
  height: 178px;
  line-height: 102px;
  text-align: center;
} */
.el-input {
  position: relative;
  display: inline-block;
  width: 27%;
}
/*/deep/ .el-input__inner{*/
/*  height: 50px;*/
/*}*/
/*/deep/.el-input__inner {*/
/*  !* height: 34px !important; *!*/
/*  height: 47px!important;*/
/*  line-height: 47px !important;*/
/*  font-size: 14px !important;*/
/*}*/
.el-form-item{
  margin-bottom: 25px;
}

.btn-defalut{
  color: #333;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 30px;
}
.btn-defalut:hover{
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #333;
}

.success{
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
}
.success:hover {
  background: #33ce73;
  border-color: #33ce73;
  color: #fff;
}
.avatar {
width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
</style>
